<template>
  <div class="card card-body filterItem" :class="{'category-d-back': hotel.supplierCode==='ATLANTIS' && hotel.category === 'D'}">
    <hot-offer-icon class="hot-offer-icon" :color="badgeColor" :text="badgeText" v-if="isSalesDeal" direction="left"/>
    <img src="/assets/img/hot-offer.png" v-if="isDiscounted && !isSalesDeal" class="hot-offer-icon" />
    <div class="row">
      <div class="col-12" :class="device==='desktop' && isOdysseySite ? 'col-md-11' : 'col-md-9 imgRoom'">
        <div class="row no-gutters flex-md-row">

          <div class="col-5 col-md-3 sportthumble d-lg-block">
            <div class="select-fav">
              <input type="checkbox" class="custom-control-input" :id="`fab-${itemIndex}`"/>
              <label class="fav" :for="`fab-${itemIndex}`"></label>
            </div>
            <img v-bind="mainProps" :src="hotel.imgUrl ? hotel.imgUrl : 'null'" :alt="hotelName || hotel.name"/>
          </div>

          <div class="col-7 col-md-9">
            <div class="row" :class="{'ml-0' : device === 'desktop'}">
              <div class="col-12 pr-md-4 d-flex flex-column position-static" :class="isFCLAgent && isOdysseySite ? 'col-md-8': 'col-md-12'">
                <div :class="device==='desktop' ? 'd-flex' : ''">
                  <div class="col-12 col-md-7 p-0">
                    <h3 class="title">
                      <a :href="`/hotel-info/?${hotelInfoQuery}`" onclick="return false;"  @click="() => gotoHotelsPage(`/hotel-info/?${hotelInfoQuery}`)">
                        {{ hotelName || hotel.name }}
                      </a>
                      <b-badge :variant="hotel.supplierCode !== 'ATLANTIS' ? 'success' : 'danger'">
                        <span v-if="hotel.supplierCode === 'ATLANTIS'">{{$t("search-result.domestic-search.request")}}</span>
                        <span v-else>{{$t("search-result.domestic-search.available")}}</span>
                      </b-badge>
                    </h3>
                  </div>
                  <div class="col-12 col-md-5 p-0">
                    <a href="#" @click="openPricePDF" class="d-flex" v-if="isOdysseySite && showHotelPricePDF()" >
                      <img class="pdf-icon" src="assets/img/pdf-icon.jpg" alt="pdf-icon"/>
                      <p class="pr-2">{{ nameHotelPriceFile }} : מחירי נטו למלון </p>
                    </a>
                  </div>
                </div>

                <span class="item-block">
                  <i class="fas fa-user-friends bluefly"></i>
                  <strong>{{ $t("search-result.domestic-search.occupancy") }}:</strong>
                  {{hotel.adults}} {{ $t("product-page.adults")}}, {{hotel.children}} {{ $t("product-page.children")}}
                </span>
                <span class="item-block">
                  <i class="fa fa-utensils bluefly"></i>
                  <strong>{{ $t("booking.board-basis") }}:</strong>
                  {{ hotel.basisName ? hotel.basisName : $t("basis-name." + hotel.basis.toLowerCase().trim())}}
                </span>
                <span class="item-block">
                  <i class="fas fa-sort-numeric-up bluefly"></i>
                  <strong>{{ $t("home.total-night") }}:</strong>
                  {{ getDuration(hotel.checkIn, hotel.checkOut) }}
                </span>
                <span class="item-block" v-if="isOdysseySite || device === 'mobile' || ((isEmalonMode || !isOdysseySite) && device === 'desktop')">
                  <img class="bluefly ml-2" src="assets/img/icon8.png" alt="bluefly" />
                  <strong>{{ $t("product-page.room-type") }}:</strong>
                  {{ hotel.roomName }}
                </span>

                <div v-if="!isOdysseySite || hotel.supplierCode === 'ATLANTIS'">
                  <span class="item-block desktop-remark" v-if="isOdysseySite && hotel.supplierCode === 'ATLANTIS'">
                    <i class="fas fa-check-circle bluefly"></i>
                    <strong>{{ $t("product-page.remarks") }}:</strong>
                    {{ hotel.remarkAgent }}
                  </span>
                  <span class="item-block desktop-remark" v-else-if="hotel.supplierCode === 'ATLANTIS'">
                    <i class="fas fa-check-circle bluefly"></i>
                    <strong>{{ $t("product-page.remarks") }}:</strong>
                    {{ hotel.remark }}
                  </span>
                  <span class="item-block desktop-remark" v-if="hotel.adminRemark && hotel.supplierCode !== 'ATLANTIS'">
                    <i class="fas fa-check-circle bluefly"></i>
                    <strong>{{ $t("product-page.remarks") }}:</strong>
                    {{ hotel.adminRemark }}
                  </span>
                  <span class="item-block desktop-remark" v-if="creditCardRemark && hotel.supplierCode !== 'ATLANTIS'">
                    <i class="fas fa-check-circle bluefly"></i>
                    <strong>{{ $t("product-page.remarks") }}:</strong>
                    {{ creditCardRemark }}
                  </span>
                </div>

                <div v-if="hotel.specialRemarks">
                  <div v-for="(specialRemark, inx) in hotel.specialRemarks" :key="inx">
                    <span v-if="!isOdysseySite || !specialRemark.displayPerPriceList">
                        <img class="bluefly ml-2" src="assets/img/icon8.png" alt="bluefly" />
                        <strong>{{ specialRemark.remark }}</strong>
                    </span>
                  </div>
                </div>

                <button class="btn btn-primary btn-selectRoom" @click="orderNow" v-if="!isOdysseySite">
                  {{ $t("search-result.order-now") }}
                </button>
              </div>
              <div class="col-12 col-md-4" v-if="isFCLAgent && isOdysseySite && device==='desktop'">
                <label>{{ $t('search-result.discount-calculator') }}</label>
                <b-form-select v-model="manualDiscount">
                  <b-form-select-option :value="inx" v-for="(n, inx) in 21" :key="inx">{{`${inx}%`}}</b-form-select-option>
                </b-form-select>
                <p class="m-0" v-if="hotel.supplierCode!=='ATLANTIS' && !hasSpecialRemark"><strong><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</strong></p>
                <p class="m-0" v-if="extraCommRemark">{{ extraCommRemark }}</p>
                <p class="m-0" v-else-if="hotel.supplierCode==='ATLANTIS' && hotel.category"><strong><span>{{ $t('search-result.category') }}: </span>{{ `${hotel.categoryName}` }}</strong></p>
                <p class="m-0"><strong style="color:red;"><span>{{ $t('product-page.available-rooms') }}: </span> {{ hotel.avail}}</strong></p>
              </div>
            </div>

            <div class="my-2 text-center" v-if="device==='desktop' && isOdysseySite && hotel.rateRemarkForAgent">
              <strong class="rate-remark">{{ hotel.rateRemarkForAgent }}</strong>
            </div>
          </div>
        </div>
        <div class="my-2 text-center" v-if="device==='mobile' && isOdysseySite && hotel.rateRemarkForAgent">
          <strong class="rate-remark">{{ hotel.rateRemarkForAgent }}</strong>
        </div>
      </div>

      <div class="actionRoom col-12 col-md-3 row">
        <div class="col-md-1" :class="isOdysseySite ? 'col-12':'col-12'"  v-if="remark || creditCardRemark && hotel.supplierCode !== 'ATLANTIS'">
          <span class="item-block mobile-remark" v-if="remark">
            <i class="fas fa-check-circle bluefly"></i>
            <strong>{{ $t("product-page.remarks") }}:</strong>
            {{ remark }}
          </span>
          <span class="item-block mobile-remark" v-if="creditCardRemark && hotel.supplierCode !== 'ATLANTIS'">
            <i class="fas fa-check-circle bluefly"></i>
            <strong>{{ $t("product-page.remarks") }}:</strong>
            {{ creditCardRemark }}
          </span>
        </div>

        <div class="col-md-2 col-5" v-if="isFCLAgent && isOdysseySite && device==='mobile'">
          <label>{{ $t('search-result.discount-calculator') }}</label>
          <b-form-select v-model="manualDiscount">
            <b-form-select-option :value="inx" v-for="(n, inx) in 21" :key="inx">{{`${inx}%`}}</b-form-select-option>
          </b-form-select>
          <p class="m-0" style="color:red;">
            <span>&nbsp;{{ $t('product-page.available-rooms') }}: </span> {{ hotel.avail}}
          </p>
        </div>
        <div class="col-md-9 col-7 mx-0 px-0" v-if="isFCLAgent && isOdysseySite && device==='mobile'">
          <p class="m-0" v-if="extraCommRemark">&nbsp;{{ extraCommRemark }}</p>
          <p class="m-0" v-else-if="hotel.supplierCode==='ATLANTIS' && hotel.category">
            <span>&nbsp;{{ $t('search-result.category') }}: </span>{{ `${hotel.categoryName}` }}
          </p>
          <p class="m-0 text-wrap" v-if="hotel.supplierCode!=='ATLANTIS' && !hasSpecialRemark">
            <span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}
          </p>
        </div>
        <div class="col-md-9 col-7" v-else-if="!isOdysseySite && device==='desktop'">
          <div v-if="hotel.discountPercent">
            <p class="m-0"><s>₪{{ hotel.total }}</s></p>
            <p class="large">₪{{ discountedPrice }}</p>
          </div>
          <div v-else>
            <p class="m-0"><s>₪{{ (Number(hotel.total) * 1.1).toFixed(0) }}</s></p>
            <p class="large">₪{{ discountedPrice }}</p>
          </div>
          <p class="m-0 price-label">{{ $t("search-result.domestic-search.final-price-message") }}</p>
          <button class="btn btn-primary btn-selectRoom" @click="orderNow">
            {{ $t("search-result.order-now") }}
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-if="false">
      <table class="table table-striped vacation-tbl">
        <thead>
          <tr>
            <th>
              <i class="fas fa-hand-holding-usd"></i><br />{{$t("search-result.domestic-search.total-to-pay")}}
            </th>
            <th>
              <i class="fas fa-hotel"></i><br />{{$t("search-result.domestic-search.basis")}}
            </th>
            <th>
              <!-- <img src="assets/img/icon8.png" alt="icon" /><br />{{ $t("search-result.domestic-search.vacation-type") }} -->
              <img src="assets/img/icon8.png" alt="icon" /><br />{{ $t("product-page.room-type") }}
            </th>
            <th>
              <i class="fas fa-moon"></i><br />{{ $t("search-result.domestic-search.no-of-nights") }}
            </th>
            <th>
              <i class="fas fa-calendar-week"></i><br />{{ $t("search-result.domestic-search.check-in") }}
            </th>
            <th>
              <i class="fas fa-calendar-week"></i><br />{{ $t("search-result.domestic-search.check-out") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> ₪{{ discountedPrice }} </td>
            <td> {{ hotel.basisName }} </td>
            <td> {{ hotel.roomName }} </td>
            <td>{{ getDuration(hotel.checkIn, hotel.checkOut) }}</td>
            <td>{{ hotel.checkIn.split("-").reverse().join("/") }}</td>
            <td>{{ hotel.checkOut.split("-").reverse().join("/") }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="!isOdysseySite || isOdysseySite && sortedReset || isEmalonMode">
      <more-hotel-price-item v-for="(h, inx) in groupedPriceListHotels" :key="inx"
        :hotel="h.hotel"
        :itemIndex="itemIndex"
        :commission="commission"
        :category="h.hotel.category"
        :supplierCode="hotel.supplierCode"
        :priceList="h.priceList"
        :hotelCode="h.hotel.exCode"
        :manualDiscount="manualDiscount"/>
    </div>

    <!-- <a class="price-down-message" v-b-modal="`price-down-${itemIndex}`" dir="ltr" v-if="!isOdysseySite && !isEmalonMode">
      {{$t("search-result.domestic-search.link-price-down-message1")}} {{hotel.name}} {{$t("search-result.domestic-search.link-price-down-message2")}}
    </a> -->

    <b-modal :id="`price-down-${itemIndex}`" centered :title="$t('search-result.domestic-search.price-down-modal-title')" size="lg"
    header-bg-variant="dark" header-text-variant="light" @show="resetModal" @hidden="resetModal">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-input required :placeholder="$t('booking.phone')" class="mb-3" v-model="phone" :state="nameState"></b-form-input>
        <b-form-input required :placeholder="$t('booking.email')" class="mb-3" v-model="email" type="email" :state="nameState"></b-form-input>
        <p>{{$t('search-result.domestic-search.price-down-modal-message')}}</p>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="goSmartAgency">
          {{ $t("search-result.domestic-search.price-down-modal-ok") }}
        </b-button>
        <b-button size="sm" variant="dark" @click="cancel()">
          {{ $t("search-result.domestic-search.price-down-modal-close") }}
        </b-button>
      </template>

    </b-modal>
  </div>
</template>
<script>
import { BBadge, VBModal, BModal, BFormInput, BButton, BFormSelect, BFormSelectOption } from 'bootstrap-vue';
import TemplateFilterItem from './TemplateFilterItem';

export default {
  name: 'FilterItemTheme0',
  mixins: [TemplateFilterItem],
  components: {
    BBadge,
    BModal,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    MoreHotelPriceItem: () => import('@/components/searchResult/atoms/MoreHotelPriceItem'),
  },
  directives: {
    'b-modal': VBModal,
  },
};
</script>
<style scoped>
.rate-remark {
  color: #007bff;
  font-size:1.2rem;
  line-height: 1.1rem;
}
.category-d-back{
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.filterItem.category-d-back .actionRoom .large {
  color: inherit;
}
.left-align .package-organize .imgRoom {
  border-left: none;
}
.left-align .package-organize .actionRoom {
  border-left: 1px dashed;
}
.bluefly {
    color: #2486c2;
    width: 20px;
}
.hot-flight {
  background-image: url("/assets/img/hot-offer-big.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50px 50px;
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -8px;
  left: -8px;
  position: absolute;
}
.dVacation-pack .ticket-topPart-big .box,
.dVacation-pack .ticket-bottomPart-big .box {
  min-width: 83px;
}
.price-down-message {
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}
.price-down-message:hover {
  color: #111;
  text-decoration: none;
  outline: none;
}
@media (max-width: 479px) {
  .hot-flight {
    background-size: 20px 20px;
  }
  .hot-offer-icon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .dVacation-pack .ticket-topPart-big .box,
  .dVacation-pack .ticket-bottomPart-big .box {
    min-width: 62px;
  }
}

.sportspage_area .actionRoom span.item-block.mobile-remark{
  display: none;
  font-size: 14px;
  line-height: 20px;
}
.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}
.ui.rating {
  padding-right: 0;
}
.itemTags {
  line-height: 30px;
}
.table td {
  text-align: center;
}
.flight-select {
  position: absolute;
  top: calc(50% - 14px);
  right: 0;
  padding: 0;
}
.filterItem {
  position: relative;
  padding: 10px;
  box-shadow: none;
}
.sportspage_area .filterItem .actionRoom .large {
  white-space: nowrap;
}
.sportspage_area .filterItem .actionRoom p, .sportspage_area .filterItem .imgRoom p {
  white-space: nowrap;
}
.sportspage_area .filterItem .actionRoom p.price-label {
  white-space: pre-line;
}
.sportspage_area .filterItem .actionRoom .ody-price .large {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .desktop-remark{
    display: none;
  }
  .sportspage_area .actionRoom span.item-block.mobile-remark{
    display: block;
  }
  .sportspage_area .filterItem .actionRoom .ody-price .large, .sportspage_area .filterItem .actionRoom .large {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  .left-align .package-organize .actionRoom {
    border-left: none;
  }
}
</style>

<style>
.flight-select .custom-control {
  padding-left: 0;
}
.flight-select .custom-control-label {
  padding-right: 1.2rem;
}
.flight-select .custom-control-label::before,
.flight-select .custom-control-label::after {
  right: 0;
}
.filterItem .pdf-icon {
  width: 20px;
  height: 20px;
}
</style>
